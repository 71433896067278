$(document).ready(function () {
  // Dropdown
  $('.js-drop__dropdown1').on('click', function () {
    $('#js-dropdown-1').css({ rotate: '180deg' }).finish();
    $('.js-drop__ul1').slideToggle(300);
  });
  $('.js-drop__dropdown2').on('click', function () {
    $('#js-dropdown-2').css({ rotate: '180deg' }).finish();
    $('.js-drop__ul2').slideToggle(300);
  });
  $('.js-drop__dropdown3').on('click', function () {
    $('#js-dropdown-3').css({ rotate: '180deg' }).finish();
    $('.js-drop__ul3').slideToggle(300);
  });

  $('#js-jobs-filter').on('click', function () {
    $('.js-filter').addClass('active');
    $('#js-filter-menu').show(500);
  });

  $(document).mouseup(function (e) {
    var container1 = $('.js-drop__ul1');
    var container2 = $('.js-drop__ul2');
    var container3 = $('.js-drop__ul3');

    var container4 = $('#js-filter-menu');

    // if the target of the click isn't the container nor a descendant of the container
    if (!container1.is(e.target) && container1.has(e.target).length === 0) {
      $('#js-dropdown-1').css({
        rotate: '0deg',
      });
      container1.hide();
    }
    if (!container2.is(e.target) && container2.has(e.target).length === 0) {
      $('#js-dropdown-2').css({
        rotate: '0deg',
      });
      container2.hide();
    }
    if (!container3.is(e.target) && container3.has(e.target).length === 0) {
      $('#js-dropdown-3').css({
        rotate: '0deg',
      });
      container3.hide();
    }

    if (!container4.is(e.target) && container4.has(e.target).length === 0) {
      container4.hide();
      $('.js-filter').removeClass('active');
    }
  });

  // Button Show More on Categories Filter
  let categoryHidden = $(document).find('[data-js="hidden"]');
  let categoryHiddenTablet = $(document).find('[data-js="hidden-tab"]');

  function showCategory() {
    categoryHidden.each(function () {
      $(this).show();
    });
  }

  function showCategoryTab() {
    categoryHiddenTablet.each(function () {
      $(this).show();
    });
  }

  $('#js-show-more').on('click', function () {
    showCategory();
    $(this).hide();
  });

  // Bottom Sheet Settings
  $('#filterSheet').downupPopup({
    headerText: '<h3>Filter</h3>',
    distance: '40',
    contentScroll: true,
  });

  $('#js-filter-button').on('click', function () {
    $('#filterSheet').downupPopup('open');
  });

  $('#js-show-more-tab').on('click', function () {
    showCategoryTab();
    $(this).hide();
  });
});
